// // Footer.js
// import React from 'react';

// const HomeFooter = () => {
//   return (
//     <footer style={footerStyle}>
//       <div style={contactStyle}>
//         <h3>Contact Us</h3>
//         <p>
//           Address: Plot No. 6 & 7, Hardware Park, Sy No. 1/1,</p>
//           <p>Srisailam Highway,Pahadi Shareef,</p>
//          <p>Via Keshavagiri (Post),
//           Hyderabad - 501510
//         </p>
//         <p>Phone: 1800 425 6235</p>
//         <p>Email: isea[at]cdac[dot]in</p>
//       </div>
//       <div style={subscribeStyle}>
//         <h3>Subscribe</h3>
//         <p>Subscribe to our email, updates and more.</p>
//         <p>Email: mail@example.com</p>
//       </div>
//     </footer>
//   );
// };

// const footerStyle = {
//   backgroundColor: '#4169E1', 
//   color: '#fff', // Change the text color to a darker color for better visibility
//   padding: '20px',
//   textAlign: 'center',
//   display: 'flex',
//   justifyContent: 'space-around',
// };

// const aboutStyle = {
//   flex: '1',
//   textAlign: 'left',
// };

// const contactStyle = {
//   flex: '1',
//   textAlign: 'left',
// };

// const subscribeStyle = {
//   flex: '1',
//   textAlign: 'left',
// };

// export default HomeFooter;

import React from "react";
import "./HomeFooter.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const HomeFooter = () => {
  return (
    <footer id="footer" className="footer">
      <div className="footer-top">
        <svg class="footer-new-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
          <path class="footer-new-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
        </svg>

        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 col-md-12 footer-info py-5">
              <p className="logo d-flex align-items-center"><h4 style={{ fontSize: "24px" }}>CISO</h4></p>
              <p className="email-us mt-4" style={{ color: "white", fontSize: "18px" }}>
                <strong>Email us at:</strong> isea-ciso[at]cdac[dot]in
              </p>

              <div className="social-links mt-5">
                <a href="https://twitter.com/InfoSecAwa" target="_blank" className="twitter">
                  <i className="fab fa-x-twitter"></i>
                </a>
                <a href="https://www.facebook.com/infosecawarenesss/" target="_blank" className="facebook">
                  <i className="bi bi-facebook" />
                </a>
                <a href="https://www.instagram.com/infosec_awareness/" target="_blank" className="instagram">
                  <i className="bi bi-instagram" />
                </a>
                <a href="https://www.linkedin.com/company/information-security-awareness/" target="_blank" className="linkedin">
                  <i className="bi bi-linkedin" />
                </a>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 footer-contact text-center text-md-start py-5">
              <h4>Contact Us</h4>
              <p className="text-white">
                <strong>Centre for Development of Advanced Computing</strong><br />
                Plot No. 6 & 7,Sy No. 1/1,<br />
                Srisailam Highway Pahadi Shareef, Via Keshavagiri Post,<br />
                Hyderabad, Telangana <br />
                <strong> Pincode:</strong> 501510<br />
                <strong>Phone: </strong> +91-9100034446/7/8
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          © Copyright{" "}
          <strong><span>C-DAC</span></strong>
          . All Rights Reserved
        </div>
      </div>
    </footer>
  );
};
export default HomeFooter;

