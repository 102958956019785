import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, HashRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Spinner from "../src/Spinner/spinner";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../src/assets/vendor/bootstrap-icons/bootstrap-icons.min.css';
import '../src/assets/css/style.css';
import '../src/assets/css/global.css';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <Suspense fallback={<Spinner />}>
      {/* <HashRouter> */}
      <BrowserRouter >
        <App />
        </BrowserRouter>
      {/* </HashRouter> */}
    </Suspense>
  // </React.StrictMode>
);

reportWebVitals();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
