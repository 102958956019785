// import React from "react";
// import Logoicon from "../../../assets/images/CISO-Logo.png";

// const LogoIcon = (props) => {
//   return <img alt="Logo" src={Logoicon} 
//   style={{ display: "block", margin: "auto" }}
//   {...props} />;
// };

// export default LogoIcon;

import React from "react";
import Logoicon from "../../../assets/images/newcisologo.png";

const LogoIcon = (props) => {
  return <img alt="Logo" src={Logoicon} 
  style={{ display: "block", margin: "auto"}}
  {...props} />;
};

export default LogoIcon;