import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Collapse } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import HomeHeader from '../../layouts/FullLayout/Header/HomeHeader1';
import Footer from '../../layouts/FullLayout/Footer/HomeFooter';

import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the CSS




const domainData = {
  "systems,-network,-and-communications-security": {
    id: 1,
    title: "Systems, Network and Communications Security",
    description: [
      "This domain focuses on securing the core infrastructure, including operating systems, networks, and communications. Effective protection starts with securing operating systems against vulnerabilities and malware, ensuring robust defenses against unauthorized access.",
      "Mobile devices and emerging technologies such as AI, ML, and blockchain require specialized security strategies. Mobile device security focuses on securing smartphones, wearables, and other connected devices from malware and unauthorized access, while emerging tech security addresses threats unique to AI models and blockchain networks. Securing web applications and databases is also critical to prevent common vulnerabilities like SQL injection and cross-site scripting.",
    "Threat analysis and engineering techniques are key to identifying, evaluating, and mitigating risks before they become critical issues. Organizations must use advanced tools and techniques to stay ahead of emerging threats, proactively defending against the evolving landscape of cyber risks.",
    ],
    image: require('../../assets/images/domain-1.png'), 
    modules: [
      "Module 1: Operating Systems Security",
      "Module 2: End Point Security",
      "Module 3: Communication Security",
      "Module 4: Internetworking and Perimeter Device Security",
      "Module 5: Introduction to Cyber Security",
      "Module 6: IT Act, Cyber Laws and their relevance"
    ],
    prerequisites: [
      "Basic knowledge of networking protocols and operating systems.",
      "Understanding of firewall and encryption concepts.",
      "Familiarity with legal aspects related to data protection and privacy laws."
    ],
    takeaways: [
      "Mastering operating system security to mitigate vulnerabilities and attacks.",
      "Implementing endpoint security strategies to safeguard devices against malware and unauthorized access.",
      "Securing communication channels through encryption technologies and secure VPNs.",
      "Deploying network security measures like firewalls and intrusion detection/prevention systems.",
      "Understanding and complying with IT and Cybersecurity laws, including regulations like the IT Act and data protection standards."
    ]
  },
  "information-security-and-security-engineering-core-competencies": {
    id: 2,
    title: "Information Security and Security Engineering Core Competencies",
    description: [
      "This domain delves into advanced technical skills needed to secure data, systems, and applications. It covers encryption technologies, which ensure data confidentiality and integrity, and explores methods to secure data in cloud environments, where new security challenges arise. Cloud security engineering involves safeguarding data, services, and workloads in cloud infrastructure, ensuring regulatory compliance and data protection.",
      "Mobile devices and emerging technologies such as AI, ML, and blockchain require specialized security strategies. Mobile device security focuses on securing smartphones, wearables, and other connected devices from malware and unauthorized access, while emerging tech security addresses threats unique to AI models and blockchain networks. Securing web applications and databases is also critical to prevent common vulnerabilities like SQL injection and cross-site scripting.",
      "Threat analysis and engineering techniques are key to identifying, evaluating, and mitigating risks before they become critical issues. Organizations must use advanced tools and techniques to stay ahead of emerging threats, proactively defending against the evolving landscape of cyber risks."
    ],
    image: require('../../assets/images/domain-2.png'),
    modules: [
      "Module 1: Encryption Technologies ",
      "Module 2: Cloud Security Engineering",
      "Module 3: Mobile Device Security (Mobiles, Drones, Wearables etc.)",
      "Module 4: Web Application and Database Security Engineering",
      "Module 5: Emerging Technologies Security (AI/ML, Blockchain etc.)",
      "Module 6: Threat Analysis and Engineering"
    ],
    prerequisites: [
      "Basic knowledge of encryption and cryptography.",
      "Understanding of cloud computing concepts and mobile device security.",
      "Familiarity with programming and development environments for web and application security."
    ],
    takeaways: [
      "Learning the principles of encryption technologies to secure data integrity and confidentiality.",
      "Gaining expertise in cloud security to protect cloud-based infrastructure and services.",
      "Securing mobile devices, IoT, and emerging technologies like AI/ML and blockchain.",
      "Mastering security engineering for web applications and databases to prevent common vulnerabilities.",
      "Developing threat analysis and engineering skills to anticipate and mitigate emerging security risks."
    ]
  },
  "asset-and-access-control-management": {
    id: 3,
    title: "Asset and access control Management",
    description: [
      "This domain focuses on the critical processes of asset management and access control to protect organizational resources. Asset management ensures that all organizational assets are identified, classified, and properly secured. Effective asset management helps prevent unauthorized access and loss of critical data and systems.",
      "Identity and access management (IAM) is a core component of this domain, ensuring that users can only access the resources necessary for their roles. This includes the use of multi-factor authentication (MFA), role-based access control (RBAC), and identity federation to ensure secure user verification and access across systems and applications.",
      "With the rise of remote work, secure access to corporate networks from external locations is essential. Remote access control management and internet access control mechanisms, including VPNs and secure protocols, protect the network perimeter. Additionally, adopting a Zero Trust model ensures that every user and device is continuously validated, regardless of location, mitigating the risk of unauthorized access."
    ],
    image: require('../../assets/images/domain-3.png'),
    modules: [
      "Module 1:  Asset Management ",
      "Module 2: Identity and Access Control Management",
      "Module 3: Remote Access Control Management",
      "Module 4: Internet Access Control Management",
      "Module 5: Social Media and Data Control Management",
      "Module 6: Zero Trust Management"
    ],
    prerequisites: [
      "Basic understanding of networking and access control systems.",
      "Knowledge of security protocols (VPN, MFA).",
      "Familiarity with identity management concepts and tools."
    ],
    takeaways: [
      "Mastering asset management techniques to identify and secure organizational assets.",
      "Implementing robust Identity and Access Management (IAM) practices, including MFA and RBAC.",
      "Designing secure remote access protocols, including VPNs and zero trust principles.",
      "Securing internet access and social media platforms from unauthorized use.",
      "Adopting Zero Trust models to ensure continuous validation of users and devices, regardless of location."
    ]
  },
  "information-security-audit-and-assessment": {
    id: 4,
    title: "Information Security Audit and Assessment",
    description: [
      "This domain is centered around auditing and assessing an organization’s security posture to ensure that all security controls are functioning as expected. The IT/ICT Audit and Assessment module emphasizes how to conduct thorough audits, identify vulnerabilities, and evaluate the overall effectiveness of security measures in place.",
      "Critical infrastructure and operational technology (OT) are highly targeted by cybercriminals, making specialized audits crucial for ensuring security in these areas. The auditing of IoT and IIoT devices is also covered, addressing the unique challenges these devices present in securing the organization’s infrastructure.",
      "Forensics-based auditing plays a significant role in investigating security breaches and preserving evidence. This domain also focuses on applying industry standards, regulations, and frameworks to ensure that security audits comply with legal and regulatory requirements, helping organizations stay compliant while addressing vulnerabilities."
    ],
    image: require('../../assets/images/domain-4.png'),
    modules: [
      "Module 1:  IT/ICT Audit and Assessment ",
      "Module 2: Audit and Assessment of Critical Infrastructure",
      "Module 3: OT/ICS Audit and Assessment ",
      "Module 4: Auditing IoT/IIoT Infrastructure",
      "Module 5: Forensic-Based Audit and Assessment ",
      "Module 6: Standards and Regulations "
    ],
    prerequisites: [
      "Knowledge of auditing standards and frameworks (e.g., ISO, NIST).",
      "Familiarity with network security and infrastructure components.",
      "Understanding of critical infrastructure, IoT, and OT environments."
    ],
    takeaways: [
      "Conducting comprehensive IT/ICT security audits to assess security measures and vulnerabilities.",
      "Auditing critical infrastructure, OT, and IoT/IIoT systems to safeguard organizational assets.",
      "Utilizing forensic-based audits to investigate security breaches and preserve evidence.",
      "Understanding and applying relevant industry standards, regulations, and compliance frameworks to security audits.",
      "Developing skills to assess and mitigate risks through the use of security audit tools and techniques."
    ]
  },
  "operations-security": {
    id: 5,
    title: "Operations Security",
    description: [
      "It covers the ongoing process of securing systems, operations, and monitoring environments. Risk management is essential to identify and mitigate risks associated with both internal and external threats. The Operations Security, Threat Landscape, and Risk Management module provides the necessary tools for assessing and managing the evolving threat landscape.",
      "Physical and personnel security is also integral to operations security. This addresses securing physical premises, protecting employees, and ensuring that sensitive information is only accessible by authorized individuals. It also focuses on preventing insider threats and ensuring that employees are trained to follow security protocols.",
      "Real-time log management is crucial for detecting security incidents as they happen. This domain teaches the importance of setting up effective log management tools to monitor systems, identify potential breaches, and respond quickly to minimize damage. Additionally, measuring security performance through metrics and developing soft skills for security leaders helps improve security operations and foster effective teamwork."
    ],
    image: require('../../assets/images/domain-5.png'),
    modules: [
      "Module 1:  Operations Security, Threat Landscape and Risk Management",
      "Module 2:  Physical and Personnel Security",
      "Module 3: Secure Configuration Management ",
      "Module 4: Real-Time Log Management",
      "Module 5: Secure Operations and Monitoring: SIEM/SOC Operations ",
      "Module 6: Security Metrics and Soft Skills "
    ],
    prerequisites: [
      "Basic knowledge of security operations, risk management, and monitoring tools.",
      "Understanding of system configuration management and personnel security protocols.",
      "Familiarity with SIEM tools and security incident management."
    ],
    takeaways: [
      "Mastering risk management techniques to assess and mitigate operational security risks.",
      "Learning to implement physical and personnel security measures to prevent insider and external threats.",
      "Securing configuration management and system setups to ensure the integrity of critical assets.",
      "Developing skills for real-time log management and using SIEM tools for security monitoring.",
      "Measuring and analyzing security metrics to gauge the effectiveness of security operations, along with building leadership and soft skills."
    ]
  },
  "business-and-strategic-management": {
    id: 6,
    title: "Business and Strategic Management ",
    description: [
      "Domain 6 of CISO training bridges business acumen with strategic cybersecurity, emphasizing how security can support organizational objectives, enhance resilience, and drive success. Key topics include digital transformation, change management, and financial management, which equip CISOs to align cybersecurity programs with business priorities, ensuring these efforts are cost-effective and support the broader strategy.",
      "Vendor and third-party management is essential for securing the increasingly vulnerable supply chain. This domain addresses trends and common pitfalls, enabling CISOs to mitigate risks and foster strategic partnerships. Emphasis is also placed on cybersecurity awareness and training, empowering employees to act as the first line of defense and comply with evolving cybersecurity and privacy regulations.",
      "Leadership, communication, and soft skills prepare CISOs to effectively manage teams, communicate risk to executives, and influence strategy. By building these competencies, CISOs can drive security initiatives that align with business goals, promote a culture of security, and ensure regulatory compliance across the organization."
    ],
    image: require('../../assets/images/domain-6.png'),
    modules: [
      "Module 1:  Introduction to Business & Strategic Management",
      "Module 2:   Digital Transformation & Change Management",
      "Module 3: Budgeting and Financial Management ",
      "Module 4: Trends and Pitfalls in Vendor and Third-Party Management (Supply Chain Management)",
      "Module 5: Security Awareness and Training ",
      "Module 6: Cybersecurity and Privacy Regulations (Emerging Regulations & Its Applications) ",
      "Module 7: Collaborative Security and Public- Private Partnerships",
      "Module 8: Emerging Trends and Future Challenges",
      "Module 9: Leadership, Communication & Soft Skills"
    ],
    prerequisites: [
      "Strong understanding of basic business management principles.",
      "Familiarity with financial management and budgeting concepts.",
      "Knowledge of vendor management and supply chain operations.",
      "Basic understanding of cybersecurity principles and compliance regulations.",
      "Experience or foundational knowledge of leadership roles and communication strategies."
    ],
    takeaways: [
      "Learn to integrate business strategies with cybersecurity management to protect organizational assets.",
      "Develop a comprehensive understanding of budgeting, financial management, and cost-effective resource allocation for security.",
      "Gain insights into managing third-party and supply chain risks, reducing vulnerabilities introduced by external vendors.",
      "Enhance security awareness and training to cultivate a security-first culture within the organization.",
      "Stay informed on emerging cybersecurity and privacy regulations to maintain legal and regulatory compliance.",
      "Cultivate leadership, communication, and soft skills to effectively manage teams, collaborate with stakeholders, and influence strategic decisions."
    ]
  },
  "secure-software-development": {
    id: 7,
    title: "Secure Software Development ",
    description: [
      "Secure Software Development of the CISO training is dedicated to secure software development practices, focusing on embedding security throughout the software lifecycle. It emphasizes secure coding principles, threat modeling, and development frameworks designed to protect software from vulnerabilities. With software increasingly central to business operations, secure coding and rigorous testing are essential for safeguarding data and preventing breaches. This domain equips CISOs with strategies to guide development teams in building resilient applications that minimize risks across diverse environments.",
      "This domain delves into the software development lifecycle, highlighting the importance of integrating security at each stage through Secure Software Development Life Cycle (SDLC) practices. It introduces DevSecOps—a unified approach combining development, security, and operations—as a powerful method to embed security into continuous integration and delivery pipelines. In addition to SDLC, the domain covers common application security threats, such as those found in web applications and databases, and provides defensive strategies to mitigate them. Specialized topics, like reverse engineering for malware and security testing, offer insights into how attackers exploit vulnerabilities and how to detect and remediate these proactively.",
      "Secure coding best practices, including input validation, error handling, and secure authentication, are key components of this domain, helping to prevent vulnerabilities from the outset. Security testing is also emphasized, with a focus on tools and techniques that identify flaws before deployment. The domain addresses emerging challenges, such as the security implications of automated code generation tools, preparing CISOs to tackle new security concerns as technology evolves."
    ],
    image: require('../../assets/images/domain-7.png'),
    modules: [
        {
          level: 1,
          items: [
            "Module 1: Introduction to Security Concepts & Need for Software Security",
            "Module 2: Software Security Threats & Development Models",
            "Module 3: Introduce secure coding best practices for Beginners",
            "Module 4: Security Testing"
          ]
        },
        {
          level: 2,
          items: [
            "Module 1: Defense Strategies for Software Threats",
            "Module 2: Web Application (OWASP) and Database Security",
            "Module 3: Mobile Application Development Security",
            "Module 4: Secure Software Development Life Cycle (SDLC)"
          ]
        },
        {
          level: 3,
          items: [
            "Module 1: Issues & Challenges with Automated Code Generation Tools",
            "Module 2: Introduction to Reverse Engineering for Malware Practices",
            "Module 3: Software Testing and Application Security Testing",
            "Module 4: DevSecOps"
          ]
        }
      ],
    prerequisites: [
      "Basic understanding of software development principles and programming languages.",
      "Familiarity with common security threats and vulnerabilities in applications (e.g., SQL injection, cross-site scripting).",
      "Prior knowledge of software development methodologies (e.g., Agile, Waterfall).",
      "Experience with basic software testing concepts and tools.",
      "Familiarity with basic DevOps practices."
    ],
    takeaways: [
      "Master the principles of secure coding to ensure software security from the ground up.",
      "Learn to apply secure coding best practices for protecting applications from common threats and vulnerabilities.",
      "Understand the importance of integrating security into the SDLC and implementing DevSecOps practices.",
      "Gain insights into security testing and vulnerability management techniques to proactively identify and mitigate software risks.",
      "Learn how to protect web and mobile applications, databases, and reverse engineer malware to better defend against threats.",
      "Address issues and challenges in using automated code generation tools and implement countermeasures to maintain secure code integrity."
    ]
  },
  "governance,-risk,-and-compliance-(grc)---sectoral-perspective": {
    id: 8,
    title: "Governance, Risk, and Compliance (GRC) - Sectoral Perspective ",
    description: [
      "This Domain of the CISO training emphasizes Governance, Risk, and Compliance (GRC) with a focus on sector-specific requirements. This domain prepares CISOs to navigate GRC needs across industries like finance, healthcare, and energy by aligning security measures with unique regulatory demands, ensuring assets are safeguarded and legal obligations are met effectively.",
      "It highlights industry-specific risk assessment and mitigation strategies that map to regulatory frameworks. Key areas include sectoral Standard Operating Procedures (SOPs), best practices for compliance, and structured risk management approaches that integrate seamlessly with operational security needs. The module also delves into third-party risk, incident response, and business continuity within GRC, equipping organizations to handle disruptions while maintaining compliance.",
      "Additionally, this domain explores emerging GRC trends, such as automation, AI, and blockchain, that reshape regulatory landscapes. This approach enables CISOs to proactively address risks, fostering a robust security culture that goes beyond compliance and prepares the organization for ongoing security and regulatory shifts."
    ],
    image: require('../../assets/images/domain-7.png'),
    modules: [
        {
          level: 1,
          items: [
            "Module 1: Introduction to the GRC Landscap",
            "Module 2: Regulatory Landscape for All Sectors",
            "Module 3: Security Frameworks Required for All Sectors and Their GRC",
            "Module 4: Threat Assessment, Detection, and Mitigation Strategies Mapping with GRC"
          ]
        },
        {
          level: 2,
          items: [
            "Module 1: Risk Assessment and Management for GRC Compliance",
            "Module 2: SOPs with Best Practices for GRC",
            "Module 3: Advanced Security Assessments for GRC",
            "Module 4: Third- Party Risk Management for GRC"
          ]
        },
        {
          level: 3,
          items: [
            "Module 1: Incident Response and Business Continuity for GRC Implementation",
            "Module 2: Emerging Trends Used in GRC Implementation",
            "Module 3: (Expert): Security Frameworks and Strategic GRC Alignment"
          ]
        }
      ],
    prerequisites: [
      "Understanding of basic governance, risk, and compliance concepts.",
      "Knowledge of core security principles and risk management frameworks.",
      "Familiarity with industry-specific regulations (e.g., GDPR, HIPAA, SOX).",
      "Experience in risk assessment and threat detection.",
      "Basic understanding of business continuity and incident response planning."
    ],
    takeaways: [
      "Gain expertise in integrating sector-specific regulatory requirements with GRC frameworks.",
      "Understand how to implement effective threat assessment, detection, and mitigation strategies aligned with GRC compliance.",
      "Learn to conduct risk assessments and manage compliance through best practices and SOPs.",
      "Develop skills to perform advanced security assessments and manage third-party risks within the GRC context.",
      "Master the process of aligning security frameworks with organizational GRC goals to enhance overall risk management.",
      "Stay informed about emerging trends and technologies shaping the future of GRC implementation and strategic alignment in various sectors."
    ]
  },
  "telecom-security": {
    id: 9,
    title: "Telecom Security ",
    description: [
      "Telecom Security focuses on the security of telecom networks and infrastructure, highlighting the unique challenges in this sector. As telecom systems are critical for communication and business operations, securing these networks is essential to protect against cyber-attacks and service disruptions. The domain covers the complexities of telecom technologies and the evolving threat landscape, preparing CISOs to secure telecom services effectively.",
      "The domain also addresses the regulatory landscape, focusing on telecom-specific regulations that organizations must comply with. CISOs will learn how to integrate security frameworks that align with telecom strategies and business objectives. Key aspects such as threat assessment, detection, and mitigation are explored, ensuring that telecom networks are secure against both internal and external risks.",
      "Finally, the domain covers advanced security assessments and third-party risk management, which are crucial for ensuring telecom infrastructure resilience. With emerging trends like 5G and IoT shaping the future, the domain provides insights into adapting to new security challenges in the telecom sector, enabling proactive risk management and incident response."
    ],
    image: require('../../assets/images/domain-7.png'),
    modules: [
        {
          level: 1,
          items: [
            "Module 1: Introduction to the Telecom Security Landscape",
            "Module 2: Regulatory Landscape for Telecom Security",
            "Module 3: Security Frameworks and Telecom Strategy Alignment",
            "Module 4: Threat Assessment, Detection, and Mitigation Strategies"
          ]
        },
        {
          level: 2,
          items: [
            "Module 1: Risk Assessment and Management for Telecom Security",
            "Module 2: Securing Telecom Networks with Best Practices",
            "Module 3: Advanced Security Assessments",
            "Module 4: Third-party Risk Management for Telecom Security"
          ]
        },
        {
          level: 3,
          items: [
            "Module 1: Incident Response and Business Continuity for Telecom Sectors",
            "Module 2: Emerging Trends in Telecom Security",
            "Module 3: (Advanced): Telecom Security Frameworks and Strategy Alignment (Expert Level)"
          ]
        }
      ],
    prerequisites: [
      "Basic understanding of telecom networks and systems.",
      "Familiarity with cybersecurity principles and risk management.",
      "Knowledge of regulatory frameworks and compliance standards.",
      "Experience with threat detection and risk assessment techniques.",
      "Understanding of business continuity and incident response practices."
    ],
    takeaways: [
      "Understand the unique security challenges in the telecom sector.",
      "Learn how to align telecom security frameworks with business strategies.",
      "Gain expertise in managing risks and mitigating threats to telecom networks.",
      "Master regulatory compliance and industry-specific security standards.",
      "Stay updated on emerging trends and new security challenges in telecom."
        ]
  },
  "cybersecurity-for-banking,-financial-services-&-insurance-(bfsi)-sector": {
    id: 10,
    title: "Cybersecurity for Banking, Financial Services & Insurance (BFSI) Sector ",
    description: [
      "It focuses on the security challenges faced by the Banking, Financial Services, and Insurance (BFSI) sector, a critical part of the global economy. As financial institutions hold sensitive customer data, they are prime targets for cyber-attacks. This domain prepares CISOs to understand the unique threat landscape of BFSI and implement strategies to safeguard financial data and infrastructure against evolving risks.",
      "The domain covers essential security strategies and frameworks tailored to the BFSI sector. It provides knowledge on aligning security with business objectives, ensuring that security measures are effectively integrated into daily operations. Key components such as risk assessment, threat detection, and incident response are explored to help mitigate the sector’s vulnerabilities.",
      "Finally, the domain addresses the importance of business continuity and third-party risk management, which are vital to maintaining trust and regulatory compliance in BFSI. Emerging trends such as digital banking, fintech, and blockchain are also discussed, helping professionals stay ahead of future security challenges in this rapidly evolving sector."
    ],
    image: require('../../assets/images/domain-7.png'),
    modules: [
        {
          level: 1,
          items: [
            "Module 1: Introduction to BFSI Security Threat Landscape",
            "Module 2: Regulatory Landscape for BFSI Security",
            "Module 3: BFSI Security Essentials & Strategy Alignment and Framework",
            "Module 4: Threat Assessment, Detection, and Mitigation Strategies"
          ]
        },
        {
          level: 2,
          items: [
            "Module 1: Risk Assessment and Management for BFSI Security",
            "Module 2: Critical Infrastructure Protection Best Practices",
            "Module 3: Advanced Security Assessments of BFSI",
            "Module 4: Third- Party Risk Management for BFSI Security"
          ]
        },
        {
          level: 3,
          items: [
            "Module 1: Incident Response and Business Continuity for BFSI Security",
            "Module 2: Emerging Trends in BFSI Security",
            "Module 3: (Advanced): BFSI Security Strategy Alignment and Frameworks (Expert Level)"
          ]
        }
      ],
    prerequisites: [
      "Basic understanding of the BFSI sector and its operations.",
      "Knowledge of cybersecurity fundamentals and risk management.",
      "Familiarity with regulatory frameworks like GDPR, PCI DSS, and SOX.",
      "Experience in risk assessment and threat detection techniques.",
      "Understanding of business continuity planning and incident response strategies."
    ],
    takeaways: [
      "Understand the security threats specific to the BFSI sector.",
      "Learn to align security strategies with the business objectives of financial institutions.",
      "Master risk management and threat mitigation strategies for BFSI security.",
      "Gain expertise in third-party risk management and incident response.",
      "Stay informed about emerging trends and security challenges in digital banking and fintech."
        ]
  },
  "internet-of-things-(iot)---industrial-iot-(iiot)-security": {
    id: 11,
    title: " Internet of Things (IoT) / Industrial IoT (IIoT) Security ",
    description: [
      "This is specific to and Emphasises on the growing security challenges within the Internet of Things (IoT) and Industrial Internet of Things (IIoT) sectors. As more devices become interconnected, the attack surface increases, making it critical for organizations to secure IoT/IIoT ecosystems. This domain helps CISOs understand the unique risks and threats posed by connected devices, particularly in industrial and critical infrastructure environments.",
      "The domain emphasizes the alignment of IoT/IIoT security strategies with organizational frameworks to ensure comprehensive protection. Key areas such as threat assessment, risk management, and mitigation strategies are explored in-depth to safeguard IoT and IIoT systems from evolving cyber threats. The application of advanced security practices to detect and respond to threats is also a major focus.",
      "Furthermore, IoT/IIoT security for critical infrastructure protection, third-party risk management, and incident response are integral components of the curriculum. Emerging trends like smart cities, automated factories, and connected vehicles are also discussed, preparing professionals for the future challenges and innovations in this rapidly expanding sector."
    ],
    image: require('../../assets/images/domain-7.png'),
    modules: [
        {
          level: 1,
          items: [
            "Module 1: Introduction to IoT/IIoT Threat Landscape",
            "Module 2: Regulatory Landscape for IoT/IIoT Security",
            "Module 3: IoT/IIoT Security Essentials & Strategy Alignment and Framework",
            "Module 4: Threat Assessment, Detection, and Mitigation Strategies for IoT/IIoT"
          ]
        },
        {
          level: 2,
          items: [
            "Module 1: Risk Assessment and Management for IoT/IIoT Security",
            "Module 2: IoT/IIoT Security for Critical Infrastructure Protection",
            "Module 3: Advanced Security Assessments of IoT/IIoT",
            "Module 4: Third- Party Risk Management for IoT/IIoT Security"
          ]
        },
        {
          level: 3,
          items: [
            "Module 1: Incident Response and Business Continuity for IoT/IIoT Security",
            "Module 2: Emerging Trends in IoT/IIoT Security",
            "Module 3: (Advanced): IoT/IIoT Security Strategy Alignment and Framework (Expert Level)"
          ]
        }
      ],
    prerequisites: [
      "Understanding of basic IoT/IIoT concepts and their applications.",
      "Knowledge of cybersecurity fundamentals and risk management.",
      "Familiarity with industrial control systems (ICS) and operational technology (OT).",
      "Experience with threat detection and incident response strategies.",
      "Awareness of key IoT/IIoT standards and regulatory frameworks."
    ],
    takeaways: [
      "Gain an understanding of the IoT/IIoT threat landscape and emerging risks.",
      "Learn how to align IoT/IIoT security strategies with business and regulatory requirements.",
      "Master risk assessment, detection, and mitigation strategies for IoT/IIoT ecosystems.",
      "Understand the importance of security practices for critical infrastructure and industrial systems.",
      "Stay informed on emerging trends and innovations in the IoT/IIoT security space."
        ]
  },
  "operational-technology-(ot)---industrial-control-systems-(ics)-security": {
    id: 12,
    title: " Operational Technology (OT) / Industrial Control Systems (ICS) Security",
    description: [
      "The main focus is on the security of Operational Technology (OT) and Industrial Control Systems (ICS), which are crucial for the functioning of critical infrastructures like energy, manufacturing, transportation, and utilities. As these systems become increasingly interconnected, they are vulnerable to cyber threats that can impact operational efficiency and safety. This domain prepares CISOs to safeguard OT/ICS environments by focusing on the unique challenges and risks associated with these technologies.",
      "Key areas covered in this domain include the alignment of ICS security strategies with business objectives and regulatory requirements. Students will learn how to assess threats, identify vulnerabilities, and implement mitigation strategies tailored for OT/ICS systems. This includes strategies for securing critical infrastructure, protecting SCADA systems, and ensuring business continuity in the face of cyber threats.",
      "The domain also explores third-party risk management and incident response, both of which are essential for a comprehensive OT/ICS security program. By understanding emerging trends and best practices, CISOs will be equipped to address the evolving cyber risks in OT/ICS environments."
    ],
    image: require('../../assets/images/domain-7.png'),
    modules: [
        {
          level: 1,
          items: [
            "Module 1: Introduction to OT/ICS Landscape",
	        "Module 2: Regulatory Landscape for OT/ICS Cybersecurity",
	        "Module 3: ICS/SCADA Security Essentials & Strategy Alignment and Framework",
	        "Module 4: Threat Assessment, Detection, and Mitigation Strategies"
          ]
        },
        {
          level: 2,
          items: [
            "Module 1: Risk Assessment and Management for OT/ICS Security",
            "Module 2: ICS Cybersecurity for Critical Infrastructure Protection Best Practices",
            "Module 3: Advanced Security Assessments of OT/ICS",
            "Module 4: Third- Party Risk Management for OT/ICS Security"
          ]
        },
        {
          level: 3,
          items: [
            "Module 1: Incident Response and Business Continuity for OT/ICS",
	"Module 2: Emerging Trends in OT/ICS Security",
	"Module 3: (Advanced): ICS/SCADA Security Strategy Alignment and Framework (Expert Level)"
          ]
        }
      ],
    prerequisites: [
      "Knowledge of basic cybersecurity principles and risk management.",
      "Familiarity with OT/ICS environments and their role in critical infrastructure.",
      "Understanding of SCADA systems and industrial automation technologies.",
      "Experience with incident response strategies and business continuity planning.",
      "Awareness of regulatory frameworks related to OT/ICS cybersecurity."
    ],
    takeaways: [
      "Understand the OT/ICS security landscape and associated risks.",
      "Develop and implement ICS/SCADA security strategies aligned with business and regulatory requirements.",
      "Master threat assessment, risk management, and mitigation techniques for OT/ICS environments.",
      "Learn best practices for securing critical infrastructure and industrial systems.",
      "Gain insights into emerging trends and future challenges in OT/ICS cybersecurity."
        ]
  },
  "cyber-security-forensics-for-leas-and-judiciary": {
    id: 13,
    title: " Cyber Security & Forensics for LEAs and Judiciary",
    description: [
      "The focus is particularly on the critical role of cybersecurity and digital forensics within law enforcement agencies (LEAs) and the judiciary system. This specialized domain provides professionals in these sectors with the tools and knowledge necessary to conduct cybercrime investigations, manage digital evidence, and ensure that all forensic processes align with legal and regulatory standards. With the rise of cybercrime, understanding how to collect, preserve, and analyze digital evidence has become a core responsibility for law enforcement officers and judiciary members.",
      "The domain covers a range of key topics, including the regulatory landscape for cyber forensics, how to align forensic frameworks with investigative strategies, and best practices for digital evidence handling. Participants will gain an understanding of various forensic disciplines, such as network forensics, mobile forensics, and incident analysis. Additionally, the domain explores emerging trends in cyber forensics, like advanced forensic tools, artificial intelligence, and machine learning, which are increasingly used to enhance the investigative process.",
      "Given the legal implications of cybercrime investigations, this domain ensures that professionals are well-equipped to handle digital evidence in ways that are legally admissible. It emphasizes maintaining the integrity of evidence, understanding the chain of custody, and applying forensic processes to support judicial proceedings."
    ],
    image: require('../../assets/images/domain-7.png'),
    modules: [
        {
          level: 1,
          items: [
            "Module 1: Introduction to Cyber Forensics & Incident Analysis",
            "Module 2: Regulatory Landscape for Cyber Forensics",
            "Module 3: Frameworks for Cyber Forensics and Strategy Alignment",
            "Module 4: Threat Assessment, Detection, and Mitigation Strategies"
          ]
        },
        {
          level: 2,
          items: [
            "Module 1: Operating System and Digital Forensics",
            "Module 2: Network Forensics",
            "Module 3: Mobile Forensics",
            "Module 4: Advanced Topics in Cyber Forensics"
          ]
        },
        {
          level: 3,
          items: [
            "Module 1: Incident Analysis and Management",
            "Module 2: Emerging Trends in Cyber Forensics & Incident Analysis"
          ]
        }
      ],
    prerequisites: [
      "Basic understanding of cybersecurity and cybercrime concepts.",
      "Familiarity with legal processes and the handling of evidence in a law enforcement context.",
      "Knowledge of digital technologies, including networks and mobile devices.",
      "Experience or foundational knowledge in investigations or forensics."
    ],
    takeaways: [
      "Learn the importance of maintaining legal and regulatory compliance during cyber forensics investigations.",
      "Gain hands-on skills in conducting network and mobile forensics, including evidence collection and analysis.",
      "Understand the forensic methodologies and tools used to investigate cybercrimes.",
      "Familiarize yourself with the latest trends and technologies in cyber forensics, such as AI-assisted investigations and advanced forensic tools.",
      "Master the process of handling digital evidence to ensure its admissibility in court and support criminal prosecutions."
        ]
  },
};

const Domain1 = () => {
  const { domainName } = useParams(); 
  const [open, setOpen] = useState(false);

  const domain = domainData[domainName];



  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Only once while scrolling
    });
  }, []);
  if (!domain) {
    return <div>Domain not found</div>;

  }

  const domainNumber = Object.keys(domainData).indexOf(domain) + 2; 

  return (
    <div>
      <HomeHeader />
      <Container className="my-5">
        <div className="text-center mb-4" data-aos="fade-up">
        <h1 style={{ fontWeight: 'bold', fontSize: '40px' }}>Domain {domain.id}</h1>
          <h1 style={{ fontWeight: '400', fontSize: '40px',marginBottom: '60px'  }}>{domain.title}</h1>
        </div>

        <Row>
  <Col md={6} data-aos="fade-right">
    <Card className="shadow-lg custom-card" style={{ height: '100%', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
        <Card.Img
          variant="top"
          src={domain.image}
          style={{ width: '90%', height: 'auto', objectFit: 'contain' }}
        />
      </div>
    </Card>
  </Col>

  <Col md={6} data-aos="fade-left">
    <Card className="shadow-lg custom-card" style={{ height: '100%', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
      <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        {domain.description.slice(0, 2).map((text, idx) => (
          <Card.Text key={idx} style={{ marginBottom: '1rem', fontSize: '18px', fontWeight: '500' }}>
            {text}
          </Card.Text>
        ))}

        <Collapse in={open}>
          <div id="extra-content">
            {domain.description[2] && (
              <Card.Text style={{ marginBottom: '1rem', fontSize: '18px', fontWeight: '500' }}>
                {domain.description[2]}
              </Card.Text>
            )}
          </div>
        </Collapse>

        {/* Position Read More/Read Less button below the content */}
        <div style={{ marginTop: 'auto' }}>
          <Button
            variant="primary"
            onClick={() => setOpen(!open)}
            aria-expanded={open}
            aria-controls="extra-content"
            style={{
              padding: '5px 10px',
              fontSize: '12px',
              borderRadius: '5px',
              backgroundColor: '#4169E1',
              color: 'white',
              width: 'auto',
              maxWidth: '150px',
              display: 'block',
              margin: '0 auto',   // Centers the button horizontally
            }}
          >
            {open ? 'Read less' : 'Read more'}
          </Button>
        </div>
      </Card.Body>
    </Card>
  </Col>
</Row>


        <div className="text-center my-5" data-aos="zoom-in">
          <h1 style={{ fontSize: '46px' }}>Modules</h1>
        </div>

        <Row className="mb-4">
  {Array.isArray(domain.modules) && domain.modules.map((module, idx) => {
    if (typeof module === "string") {
      return (
        <Col md={6} key={idx} className="mb-3" data-aos="fade-up">
          <Card style={{ backgroundColor: '#446dc1', color: 'white' }}>
            <Card.Body>
              <Card.Title>{module}</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      );
    }
    
    if (module.level && module.items && Array.isArray(module.items)) {
      return (
        <Col md={6} key={idx} className="mb-3" data-aos="fade-up">
          <Card style={{ backgroundColor: '#446dc1', color: 'white' }}>
            <Card.Body>
              <Card.Title>{`Level ${module.level}`}</Card.Title>
              <ul>
                {module.items.map((item, itemIdx) => (
                  <li key={itemIdx}>{item}</li>
                ))}
              </ul>
            </Card.Body>
          </Card>
        </Col>
      );
    }

    return null; 
  })}
</Row>

        <Row className="my-5" data-aos="fade-up">
          <Col md={8} className="mx-auto">
            <Card style={{ boxShadow: '10px 10px 15px 0 rgba(0, 0, 0, 0.1), -5px -5px 15px 0 rgba(0, 0, 0, 0)', borderBottom: '10px solid #478ac9' }}>
              <Card.Body>
                <Card.Title style={{ fontSize: '30px', fontWeight: 'bold' }}>Pre-requisites:</Card.Title>
                <Card.Text style={{ fontSize: '18px', fontWeight: '500' }}>
                  <ul>
                    {domain.prerequisites.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="my-5" data-aos="fade-up">
          <Col md={8} className="mx-auto">
            <Card style={{ boxShadow: '10px 10px 15px 0 rgba(0, 0, 0, 0.1), -5px -5px 15px 0 rgba(0, 0, 0, 0)', borderBottom: '10px solid #478ac9' }}>
              <Card.Body>
                <Card.Title style={{ fontSize: '30px', fontWeight: 'bold' }}>Key Takeaways:</Card.Title>
                <Card.Text style={{ fontSize: '18px', fontWeight: '500' }}>
                  <ul>
                    {domain.takeaways.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default Domain1;
