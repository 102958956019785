import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LogoIcon from "../Logo/LogoIcon";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { IVP_BASE_URL } from "../../../config/config";
import Ivp from "../../../IVP/ivp";
import "./HomeHeader.css";

const blueButtonStyle = {
  color: "#ffffff",
  backgroundColor: "#007bff",
  borderColor: "#007bff",
  padding: "0.375rem 0.75rem",
  borderRadius: "1.25rem",
  textDecoration: "none",
  display: "inline-block",
};

const blueButtonHoverStyle = {
  backgroundColor: "#0056b3",
  borderColor: "#004187",
};

const HomeHeader = ({
  onOverviewClick,
  onCourseClick,
  onTrainingClick,
  onExpertClick,
  onChooseClick,
}) => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [redirected, setRedirected] = useState(false);
  const [isHovered, setIsHovered] = useState(false);


  useEffect(() => {
    const accessToken = Cookies.get("access_token");
    setIsLoggedIn(!!accessToken);

    if (accessToken) {
      const decodeJWT = jwtDecode(accessToken);
      setUserRole(decodeJWT.role);
    }
  }, [location]);

  const handleLoginRedirect = () => {
    const redirectUrl = encodeURIComponent(window.location.pathname);
    window.location.href = `${IVP_BASE_URL}loginRedirect?client_id=ciso&source=ciso&redirect=${redirectUrl}`;
    setRedirected(true);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      const navbar = document.querySelector("#navbar");

      if (
        navbar &&
        navbar.classList &&
        navbar.classList.contains("navbar-mobile") &&
        event.target.matches(".navbar .dropdown > a")
      ) {
        event.preventDefault();

        const nextSibling = event.target.nextElementSibling;
        if (nextSibling && nextSibling.classList) {
          nextSibling.classList.toggle("dropdown-active");
        }
      }
    }

    function handleDropdownClick(event) {
      if (!event.target) {
        return;
      }
      const navbar = document.querySelector("#navbar");
      if (
        navbar &&
        navbar.classList &&
        navbar.classList.contains("navbar-mobile") &&
        event.target.matches(".navbar .dropdown > a")
      ) {
        event.preventDefault();
        event.target.nextElementSibling.classList.toggle("dropdown-active");
      }
    }

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("click", handleDropdownClick, true);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("click", handleDropdownClick, true);
    };
  }, []);

  return (
    <>
      <div className="col-lg-6 col-md-6">
        <div className="top-left" style={{ marginLeft: "140px" }}>
          <span className="social_icons">
            <a
              href="https://www.facebook.com/infosecawarenesss/"
              target="_blank"
              title="Visit Our Facebook Page (External Website that opens in a new window)"
            >
              <i className="fa-brands fa-square-facebook"></i>
            </a>
            <a
              href="https://twitter.com/InfoSecAwa"
              target="_blank"
              title="Visit Our Twitter Page (External Website that opens in a new window)"
            >
              <i className="fa-brands fa-x-twitter"></i>
            </a>
            <a
              href="https://www.youtube.com/c/InformationSecurityAwareness"
              target="_blank"
              title="Visit Our YouTube Page (External Website that opens in a new window)"
            >
              <i className="fa-brands fa-youtube"></i>
            </a>
            <a
              href="https://www.instagram.com/infosec_awareness/"
              target="_blank"
              title="Visit Our Instagram Page (External Website that opens in a new window)"
            >
              <i className="fa-brands fa-instagram"></i>
            </a>
          </span>
        </div>
      </div>

      <nav
        className="navbar navbar-expand-lg navbar-light bg-light py-3"
        style={{
          background:
            "radial-gradient(circle at 10% 20%, #005CAB 0%, #023B6D 90.1%)",
        }}
      >
        <div className="container">
          <div className="container">
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ width: "90%", maxWidth: "1400px" }}
            >
              <LogoIcon style={{ width: "250px", height: "auto" }} />
              <i
                className="fas fa-user-circle"
                style={{
                  color: "white",
                  fontSize: "3.5rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (isLoggedIn) {
                    switch (userRole) {
                      case "instructor":
                        window.location.href = "/instructor_dashboard";
                        break;
                      case "reviewer":
                        window.location.href = "/reviewerdashboard";
                        break;
                      case "admin":
                        window.location.href = "/admindashboard";
                        break;
                      case "coordinator":
                        window.location.href = "/coordinator_dashboard";
                        break;
                      default:
                        window.location.href = "/dashboard";
                        break;
                    }
                  } else {
                    handleLoginRedirect();
                  }
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)} 
              >
                <div
                  style={{
                    display: isHovered ? "block" : "none", 
                    position: "absolute",
                    backgroundColor: "#E5E4E2",
                    color: "#191970",
                    padding: "10px 10px",
                    fontSize: "0.9rem",
                    zIndex: 5,
                  }}
                >
                  {isLoggedIn ? "Dashboard" : "Login / Register"}
                </div>
              </i>
            </div>
            <div style={{ width: "100%", backgroundColor: "white" }}>
              <hr
                style={{
                  border: "none",
                  borderTop: "2px solid white",
                  margin: "20px 0 5px",
                  backgroundColor: "transparent",
                }}
              />
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse d-flex flex-column align-items-start"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mb-2 mb-lg-0 pt-3 pt-lg-0 d-flex flex-row gap-4">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/"
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "400",
                      color: "white",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                <Link
                    className="nav-link"
                    to="/"
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "400",
                      color: "white",
                    }}
                  >
                    Overview
                  </Link>
                </li>
                <li className="nav-item">
                <Link
                    className="nav-link"
                    to="/"
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "400",
                      color: "white",
                    }}
                  >
                    Course Details
                  </Link>
                </li>
                <li className="nav-item">
                <Link
                    className="nav-link"
                    to="/"
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "400",
                      color: "white",
                    }}
                  >
                    Our Experts
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link navactive"
                    to="/ciso_calendar"
                    style={{
                      cursor: "pointer",
                      fontSize: "1.2rem",
                      fontWeight: "400",
                      color: "white",
                    }}
                  >
                    CISO Calendar
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {redirected && <Ivp />}
    </>
  );
};

export default HomeHeader;

