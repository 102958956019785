// import Services from "../Services/Services";
// import React, { useState, useEffect } from "react";
// // import BeatLoader from "react-spinners/BeatLoader";
// // import jwtDecode from 'jwt-decode';
// import { useNavigate } from "react-router-dom";
// import Spinner from "../Spinner/spinner";
// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";
// import Dashboard1 from "../views/User/Dashboard1";
// import Cisodashboard from "../views/CISO/cisodashboard";

// // import swal from "sweetalert";

// const Ivp = () => {
//   const [loading, setLoading] = useState(true);
//   const [color, setColor] = useState("#ffffff");
//   const [isLoading, setIsLoading] = useState(true);
//   const [email, setEmail] = useState("");

//   let navigate = useNavigate();

//   useEffect(() => {
//     const url = new URL(window.location.href);
//     const code = url.searchParams.get("code");
//     // const code = window.location.href.split("=")[3];
//     const body = { code };
//     Services.Ivpauthorize(body).then((res) => {
//       if (res.data.status === 1) {
//         let access_token = res.data.data.data.access_token;
//         let refresh_token = res.data.data.data.refresh_token;

//         //et token="eyJhbGciOiJSUzUxMiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJxU0FTZzRGd0QzdmZQX1pjUTlOSGczUEJudWd6TlQ1cFhmRS0zT1BJYVM4In0.eyJleHAiOjE2OTcxMTI0MDUsImlhdCI6MTY5NzEwODgwNiwiYXV0aF90aW1lIjoxNjk3MTA4NjIwLCJqdGkiOiIxMDkwM2I4ZC01YThjLTQ3NmYtODU1Mi0zMjc3MTEyMmU0MDgiLCJpc3MiOiJodHRwOi8vZGhhbmFuamF5Lmh5ZGVyYWJhZC5jZGFjLmluOjgwODAvYXV0aC9yZWFsbXMvY2RhYzIwMjIiLCJhdWQiOlsicmVhbG0tbWFuYWdlbWVudCIsImFjY291bnQiXSwic3ViIjoiNDM4Njc0MzgtYmVjMy00OWRjLWJmNDgtOGQ0ZmFhNDBlMTkzIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoidmxhYiIsInNlc3Npb25fc3RhdGUiOiJjMDU4ZjYxZC02YzVhLTQ0ZjgtOTAwMS03NDk3MDhmZjA4NzUiLCJhY3IiOiIwIiwiYWxsb3dlZC1vcmlnaW5zIjpbImh0dHA6Ly8xMC4yNDQuMS42MTo4MDgwIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJkZWZhdWx0LXJvbGVzLWNkYWMyMDIyIiwiYWRtaW4iLCJ1c2VyIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsicmVhbG0tbWFuYWdlbWVudCI6eyJyb2xlcyI6WyJ2aWV3LWlkZW50aXR5LXByb3ZpZGVycyIsInZpZXctcmVhbG0iLCJtYW5hZ2UtaWRlbnRpdHktcHJvdmlkZXJzIiwiaW1wZXJzb25hdGlvbiIsInJlYWxtLWFkbWluIiwiY3JlYXRlLWNsaWVudCIsIm1hbmFnZS11c2VycyIsInF1ZXJ5LXJlYWxtcyIsInZpZXctYXV0aG9yaXphdGlvbiIsInF1ZXJ5LWNsaWVudHMiLCJxdWVyeS11c2VycyIsIm1hbmFnZS1ldmVudHMiLCJtYW5hZ2UtcmVhbG0iLCJ2aWV3LWV2ZW50cyIsInZpZXctdXNlcnMiLCJ2aWV3LWNsaWVudHMiLCJtYW5hZ2UtYXV0aG9yaXphdGlvbiIsIm1hbmFnZS1jbGllbnRzIiwicXVlcnktZ3JvdXBzIl19LCJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6InByb2ZpbGUgZW1haWwiLCJzaWQiOiJjMDU4ZjYxZC02YzVhLTQ0ZjgtOTAwMS03NDk3MDhmZjA4NzUiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsInJvbGUiOiJhZG1pbiIsIm5hbWUiOiJhZnRhYiBrYXppIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiY2RhY0BjZGFjLmluIiwiZ2l2ZW5fbmFtZSI6ImFmdGFiIiwiZmFtaWx5X25hbWUiOiJrYXppIiwiZW1haWwiOiJjZGFjQGNkYWMuaW4ifQ.S0H8bm2UQM2UNXsjz4kdqCZLS3mTEJa6_QJzzYyL2YRdTmL5VwWqpZvHYLH3w_ya2xp6c2ad2aBKIwbrY1jj1kZK-qZEJtkekeExI75gMShozUubgSskddcnJ4HJXh0oHaYZLYS1Wa--NTj8bJNIAjiTJSm_JEG16T9vLGoofgpqIpEZcMFkf_rgGZA4ezX7aqfV20q19BeEf3XLI9M-NfEH3O04sUFo938X_wTIiPqkxCF5sJ6Na5cA7ux1NIj7XRNN1VLpGoV9nc5MSzpxDanVDZoluFo0gyLOn5OzxI2APMlwvA21dvRtiJmhftQVaEqbcnBqH_eneFnWZyLFlQ"
//         const data = {};
//         Services.Userinfo(access_token, refresh_token)
//           .then((response) => {
//             if (response.status === 200) {
//               let bodyjson = {
//                 email_id: response.data.data.email,
//                 name: response.data.data.first_name,

//               };
//               Cookies.set("user_id", response.data.data.user_id);
//               Cookies.set("ivpflag", 1);

//               Services.IvpLogin(bodyjson)
//                 .then((response) => {
//                   setTimeout(() => {
//                     const {
//                       status,
//                       message,
//                       access_token,
//                       refresh_token,
//                       session_key,
//                     } = response.data;
//                     if (response.status === 200) {
//                       Cookies.set("access_token", access_token);
//                       Cookies.set("session_key", session_key);
//                       const decodeJWT = jwtDecode(access_token);
//                       const role = decodeJWT.role;
//                       // navigate("/ciso", { state: role });
//                       // navigate("/ciso", { state: { role, email } });
//                       if (role === "user") {
//                         navigate("/ciso", { state: { role, email } });
//                       } else if (role === "instructor") {
//                         navigate("/dashboard1");
//                       }

//                       // Reload the page to reflect the changes
//                       window.location.reload();

//                       // if (role === "instructor") {
//                       //   navigate("/dashboard1");
//                       //   window.location.reload();
//                       // } else if (role === "user") {
//                       //   navigate("/dashboard");
//                       //   window.location.reload();
//                       // }
//                     }
//                     setIsLoading(false);
//                   }, 2000);
//                 })
//                 .catch((err) => {
//                   if (err.response && err.response.status === 401) {
//                     // swal("Error", err.response.data.error, "error");
//                   } else if (
//                     err.response &&
//                     err.response.data &&
//                     err.response.data.errors
//                   ) {
//                     let errorMessages = Object.values(
//                       err.response.data.errors
//                     ).join("\n");
//                     // swal("Message!", errorMessages, "warning");
//                     //setFieldValue('captcha', '');
//                   } else if (
//                     err.response &&
//                     err.response.data &&
//                     err.response.data.msg
//                   ) {
//                     // swal("Message!", err.response.data.msg, "warning");
//                   } else {
//                     // swal("Message!", "Server error occurred", "warning");
//                   }
//                 });
//             }
//           })
//           .catch((err) => {
//             if (err.response && err.response.status === 401) {
//               // swal("Error", err.response.data.error, "error");
//             } else if (
//               err.response &&
//               err.response.data &&
//               err.response.data.errors
//             ) {
//               let errorMessages = Object.values(err.response.data.errors).join(
//                 "\n"
//               );
//               // swal("Message!", errorMessages, "warning");
//               //setFieldValue('captcha', '');
//             } else if (
//               err.response &&
//               err.response.data &&
//               err.response.data.msg
//             ) {
//               // swal("Message!", err.response.data.msg, "warning");
//             } else {
//               // swal("Message!", "Server error occurred", "warning");
//             }
//           });
//       }
//     });
//   }, []);

//   return isLoading ? <Spinner /> : <div></div>;
// };

// export default Ivp;
// =====================================correct code=================================================

// import Services from "../Services/Services";
// import React, { useState, useEffect, CSSProperties } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import Spinner from "../Spinner/spinner";
// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";
// import Dashboard1 from "../views/User/Dashboard1";
// import Cisodashboard from "../views/CISO/cisodashboard";

// // import swal from "sweetalert";

// const Ivp = () => {
//   const [loading, setLoading] = useState(true);
//   const [color, setColor] = useState("#ffffff");
//   const [isLoading, setIsLoading] = useState(true);
//   const [email, setEmail] = useState("");

//   let navigate = useNavigate();

//   const secureFlag =
//     window.location.protocol === "https:"
//       ? { secure: true, sameSite: "strict" }
//       : { secure: false, sameSite: "lax" };

//   useEffect(() => {
//     const url = new URL(window.location.href);
//     const code = url.searchParams.get("code");
//     if (code) {
//       const body = { code, client_id: "ciso" };
//       Services.Ivpauthorize(body).then((res) => {
//         if (res.data.status === 1) {
//           let access_token = res.data.data.data.access_token;
//           let refresh_token = res.data.data.data.refresh_token;

//           const data = {};
//           Services.Userinfo(access_token, refresh_token)
//             .then((response) => {
//               if (response.status === 200) {
//                 Cookies.set("user_id", response.data.data.user_id);
//                 Cookies.set("ivpflag", 1);
//                 let bodyjson = {
//                   email_id: response.data.data.email,
//                   // name: response.data.data.first_name,
//                   name: `${response.data.data.first_name} ${response.data.data.last_name}`,
//                   user_id : response.data.data.user_id
//                 };

//                 Services.IvpLogin(bodyjson)
//                   .then((response) => {
//                     setTimeout(() => {
//                       const {
//                         status,
//                         message,
//                         access_token,
//                         refresh_token,
//                         session_key,
//                       } = response.data;
//                       if (response.status === 200) {
//                         Cookies.set("access_token", access_token);
//                         Cookies.set("refresh_token", refresh_token);
//                         Cookies.set("session_key", session_key);
//                         Cookies.set("LoggedIn", true);

//                         const decodeJWT = jwtDecode(access_token);
//                         const role = decodeJWT.role;
//                         const email = decodeJWT.email_id;
//                         // navigate("/ciso", { state: role });
//                         // navigate("/ciso", { state: { role, email } });
//                         if (role === "user") {
//                           navigate("/ciso", { state: { role, email } });

//                           window.location.reload();
//                         } else if (role === "instructor") {
//                           navigate("/instructor_dashboard");
//                           // navigate("/dashboard1");
//                           window.location.reload()
//                         }else if (role === "reviewer") {
//                           navigate("/reviewerdashboard");
//                           window.location.reload()
//                         }else if (role === "admin") {
//                            navigate("/admindashboard");
//                            window.location.reload()
//                         }else if (role === "coordinator") {
//                           navigate("/coordinatordashboard");
//                           window.location.reload();
//                         }
//                       }
//                       setIsLoading(false);
//                     }, 2000);
//                   })
//                   .catch((err) => {
//                     if (err.response && err.response.status === 401) {
//                     } else if (
//                       err.response &&
//                       err.response.data &&
//                       err.response.data.errors
//                     ) {
//                       let errorMessages = Object.values(
//                         err.response.data.errors
//                       ).join("\n");
//                     } else if (
//                       err.response &&
//                       err.response.data &&
//                       err.response.data.msg
//                     ) {
//                     } else {
//                     }
//                   });
//               }
//             })
//             .catch((err) => {
//               if (err.response && err.response.status === 401) {
//               } else if (
//                 err.response &&
//                 err.response.data &&
//                 err.response.data.errors
//               ) {
//                 let errorMessages = Object.values(
//                   err.response.data.errors
//                 ).join("\n");
//               } else if (
//                 err.response &&
//                 err.response.data &&
//                 err.response.data.msg
//               ) {
//               } else {
//               }
//             });
//         }
//       });
//     }
//   }, []);
//   return isLoading ? <Spinner /> : <div></div>;
// };

// export default Ivp;

//================================= code ===================================================

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../Spinner/spinner";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import Services from "../Services/Services";
import { encrypt, decrypt } from "../Utils/cryptoUtils";
import IvpProfile from "../views/IvpProfile";

const Ivp = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  let navigate = useNavigate();

  const secureFlag =
    window.location.protocol === "https:"
      ? { secure: true, sameSite: "strict" }
      : { secure: false, sameSite: "lax" };

  useEffect(() => {
    const url = new URL(window.location.href);
    const ivp_code = url.searchParams.get("code");
    const ivp_state = url.searchParams.get("state");

    if (ivp_code, ivp_state) {
      const data = { ivp_code, ivp_state };
      Services.IvpLogin(data).then((response) => {
          const { status, data } = response.data;
          const { access_token,refresh_token,session_key,user_id,acc_rew_tok, } = data || {};

          console.log("Access Token:", access_token);
          console.log("Refresh Token:", refresh_token);
          console.log("Bearer Token:", acc_rew_tok);
          console.log("Session Key:", session_key);
          console.log("User_id:", user_id);

          if (status === 200 && typeof access_token === "string") {
            setShowProfileModal(false);
            const encryptedUserId = encrypt(user_id);
            Cookies.set("access_token", access_token, { expires: 1, ...secureFlag,});
            Cookies.set("bearer_token", acc_rew_tok, { expires: 1, ...secureFlag,});
            Cookies.set("abc", encryptedUserId, { expires: 1, ...secureFlag });
            Cookies.set("refresh_token", refresh_token, { expires: 1, ...secureFlag,});
            Cookies.set("session_key", session_key, { expires: 1, ...secureFlag,});
            Cookies.set("LoggedIn", true, { expires: 1, ...secureFlag });
            const getLocal = window.localStorage.getItem("encryptedQuizCode");

            let decryptedLocalValue;
            try {
              decryptedLocalValue = decrypt(getLocal);
              console.log("Decrypted Local Value:", decryptedLocalValue);
            } catch (decryptionError) {
              console.error("Decryption error:", decryptionError);
            }
            try {
              const decodeJWT = jwtDecode(access_token);
              const role = decodeJWT.role;
              const email = decodeJWT.email_id;

              if (role === "user") {
                if (decryptedLocalValue) {
                  navigate(`/quiz/${decryptedLocalValue}`);
                } else {
                  console.log("Navigating to dashboard");
                  navigate("/dashboard");
                }
              } else if (role === "instructor") {
                navigate("/instructor_dashboard", { state: { role, email } });
              } else if (role === "reviewer") {
                navigate("/reviewerdashboard");
              } else if (role === "admin") {
                navigate("/admindashboard");
              } else if (role === "coordinator") {
                navigate("/coordinatordashboard");
              }
              window.location.reload();
            } catch (decodeError) {
              console.error("Error decoding JWT:", decodeError);
            }
          } else {
            console.error("Invalid access token or status not 200:",access_token);
            setShowProfileModal(true);
            if (data && data.user_id) {
              const ivpUser = data.user_id;
              const encryptedUserId = encrypt(ivpUser);
              Cookies.set("abc", encryptedUserId, {expires: 1, ...secureFlag,});
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Error during IvpLogin:", err.response);
          if (
            err.response &&
            err.response.status === 404 &&
            err.response.data.message === "Invalid user info data"
          ) {
            setShowProfileModal(true);
            const ivpUser = err.response.data.user_id;
            const encryptedUserId = encrypt(ivpUser);
            Cookies.set("abc", encryptedUserId, { expires: 1, ...secureFlag });
          }
          setIsLoading(false);
        });
    }
  }, [navigate]);

  console.log("showProfileModal", showProfileModal);
  return (
    <>
      {isLoading ? <Spinner /> : null}
      {showProfileModal && (
        <IvpProfile
          onClose={() => setShowProfileModal(false)}
          open={showProfileModal}
        />
      )}
    </>
  );
};

export default Ivp;

//===========================================================================================//
// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import Spinner from "../Spinner/spinner";
// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";
// import Services from "../Services/Services";
// import { encrypt } from "../Utils/cryptoUtils";
// import IvpProfile from "../views/IvpProfile";

// const Ivp = () => {

//   const [isLoading, setIsLoading] = useState(true);
//   const [loggedIn, setLoggedIn] = useState(false);
//   let navigate = useNavigate();

//   const secureFlag =
//     window.location.protocol === "https:"
//       ? { secure: true, sameSite: "strict" }
//       : { secure: false, sameSite: "lax" };

//   useEffect(() => {
//     const url = new URL(window.location.href);
//     const ivp_code = url.searchParams.get("code");

//     if (ivp_code) {
//       const data = { ivp_code };
//       Services.IvpLogin(data)
//         .then((response) => {
//           setTimeout(() => {
//             const { status, message, data } = response.data;
//             const { access_token, refresh_token, session_key, user_id, acc_rew_tok} =
//               data || {};

//             console.log("Access Token:", access_token);
//             console.log("Refresh Token:", refresh_token);
//             console.log("Bearer Token:", acc_rew_tok);
//             console.log("Session Key:", session_key);
//             console.log("User_id:", user_id);

//             if (status === 200 && typeof access_token === "string") {
//               Cookies.set("access_token", access_token, {expires: 1,...secureFlag,});
//               Cookies.set("bearer_token", acc_rew_tok, {expires: 1,...secureFlag,});
//               const encryptedUserId = encrypt(user_id);
//               Cookies.set("abc", encryptedUserId, { expires: 1, ...secureFlag }); //userID
//               // Cookies.set("user_id", user_id, { expires: 1, ...secureFlag });
//               Cookies.set("refresh_token", refresh_token, {expires: 1,...secureFlag,});
//               Cookies.set("session_key", session_key, {expires: 1,...secureFlag,});
//               Cookies.set("LoggedIn", true, { expires: 1, ...secureFlag });

//               try {
//                 const decodeJWT = jwtDecode(access_token);
//                 const { role, email_id: email } = decodeJWT;

//                 if (role === "user") {
//                   navigate("/dashboard");
//                 // if (role === "user") {
//                 //   navigate("/ciso", { state: { role, email } });
//                 } else if (role === "instructor") {
//                   navigate("/instructor_dashboard");
//                 } else if (role === "reviewer") {
//                   navigate("/reviewerdashboard");
//                 } else if (role === "admin") {
//                   navigate("/admindashboard");
//                 } else if (role === "coordinator") {
//                   navigate("/coordinatordashboard");
//                 }

//                 window.location.reload();
//               } catch (decodeError) {
//                 console.error("Error decoding JWT:", decodeError);
//               }
//             } else {
//               console.error(
//                 "Invalid access token or status not 200:",
//                 access_token
//               );
//             }

//             setIsLoading(false);
//           }, 2000);
//         })
//         .catch((err) => {
//           console.error("Error during IvpLogin:", err);
//           setIsLoading(false);
//         });
//     }
//   }, [navigate]);

//   return isLoading ? <Spinner /> : <div></div>;
// };

// export default Ivp;

//------------------------single token ---------------------------------------

// import Services from "../Services/Services";
// import React, { useState, useEffect, CSSProperties } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import Spinner from "../Spinner/spinner";
// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";
// import Dashboard1 from "../views/User/Dashboard1";
// import Cisodashboard from "../views/CISO/cisodashboard";

// // import swal from "sweetalert";

// const Ivp = () => {
//   const [loading, setLoading] = useState(true);
//   const [color, setColor] = useState("#ffffff");
//   const [isLoading, setIsLoading] = useState(true);
//   const [email, setEmail] = useState("");

//   let navigate = useNavigate();

//   const secureFlag =
//     window.location.protocol === "https:"
//       ? { secure: true, sameSite: "strict" }
//       : { secure: false, sameSite: "lax" };

//   useEffect(() => {
//     const url = new URL(window.location.href);
//     const code = url.searchParams.get("code");

//     if (code) {
//       const body = { code, client_id: "ciso" };
//       const getTokenGen = async () => {
//         try {
//           Services.Ivpauthorize(body).then((res) => {
//               if (res.data.status === 1) {
//                 let access_token = res.data.data.data.access_token;
//                 let refresh_token = res.data.data.data.refresh_token;

//                 const data = {};
//                 Services.Userinfo(access_token, refresh_token)
//                   .then((response) => {
//                     if (response.status === 200) {
//                       let bodyjson = {
//                         email_id: response.data.data.email,
//                         name: response.data.data.name,
//                       };

//                       Cookies.set("access_token", access_token);
//                       Cookies.set("refresh_token", refresh_token);
//                       Cookies.set("LoggedIn", true);
//                       const decodeJWT = jwtDecode(access_token);
//                       const role = decodeJWT.role;
//                       const email = decodeJWT.email;
//                         if (role === "user") {
//                           navigate("/ciso", { state: { role, email } });

//                           window.location.reload();
//                         } else if (role === "instructor") {
//                           navigate("/dashboard1");
//                           window.location.reload()
//                         }else if (role === "reviewer") {
//                           navigate("/reviewerdashboard");
//                           window.location.reload()
//                         }else if (role === "admin") {
//                            navigate("/admindashboard");
//                            window.location.reload()
//                         }
//                       }
//                     }, 2000)
//                   .catch((err) => {
//                     if (
//                       err.response &&
//                       err.response.data &&
//                       err.response.data.status === 0
//                     ) {
//                       // swal("Message!", err.response.data.errors, "warning");
//                     }
//                   });
//               }
//             });
//         } catch (err) {
//           if (
//             err.response &&
//             err.response.data &&
//             err.response.data.status === 0
//           ) {
//             // swal("Message!", err.response.data.errors, "warning");
//           }
//         }
//       };
//       getTokenGen();
//     }
//   }, []);

//   return isLoading ? <Spinner /> : <div></div>;
// };

// export default Ivp;
